import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import { Breadcrumb } from "../components";
import axios from "axios";
import { BACKEND_BASE_URL } from "../constant/constants";

const breadcrumbData = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Worship Resources",
  },
];

const PrayerCalender = () => {
  const [eventDataModal, setEventdataModal] = useState({ event: "" });

  const renderEventContent = eventInfo => {
    return (
      <button
        type="button"
        className="calendar-event"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        onClick={() =>
          setEventdataModal({
            event: eventInfo.event.title,
          })
        }
      >
        {eventInfo.event.title}
      </button>
    );
  };

  return (
    <div className="daily-bible">
      <div className="container">
        <div className="mt-5">
          <Breadcrumb data={breadcrumbData} />
        </div>
        <h3 className="daily-bible__title">Worship Resources</h3>
        <span className="daily-bible__sub-title">Prayer Calendar</span>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModal"
          aria-hidden="true"
          data-bs-backdrop="false"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Event Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Event Name : {eventDataModal?.event}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="daily-bible__content">
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            showNonCurrentDates={false}
            eventContent={renderEventContent}
            events={async (fetchInfo, successCallback, failureCallback) => {
              const year = fetchInfo.start.getFullYear();
              const month = fetchInfo.start.getMonth();
              await axios
                .get(BACKEND_BASE_URL + `prayer-calendar/${year}/${month + 1}`)
                .then(async res => {
                  if (res) {
                    const eventData = await res?.data?.map(item => {
                      return { title: item.title, date: item.date };
                    });
                    successCallback(eventData);
                  }
                })
                .catch(err => {
                  console.log(err);
                  failureCallback();
                });
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default PrayerCalender;
