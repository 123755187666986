import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import PrayerCalender from "../container/prayer-calender";

const PrayerCalendar = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Worship Resource" />
      <PrayerCalender location={location} />
    </Layout>
  );
};

export default PrayerCalendar;
